@media screen and (max-height:600px){
    .menu-list{
        padding: 40px;
        position: static;
    }
    .menu-block .social{
        position: static;
    }
    .menu-block{
        overflow: auto;
    }
}
@media screen and (max-width:1300px){
    .infrastructure-content .info li{
        width:100%;
    }
    .partners-slider{
        .partner-item{
            &>p span{
                clear: both;
                display: block;
                
            }
        }
    }
}
@media screen and (max-width:1260px){
    .newsWrap{
        .header__logo img:last-child{
            width:150px;
        }
        .header__logo img:first-child{
            width: 50px;
        }
    }
}
@media screen and (max-width:1215px){
    .blog-item .hm-action-box__cont{
        display: none;
    }
    .blog-item .hm-action-box img{
        width:0;
    }
    
}
@media screen and (max-width:1130px){
    .infrastructure-content img{
        width:490px;
        margin-top:25px;
        margin-right: 20px;
    }
    .rent-2-content .images{
        width: 347px;
        height: 244px;
        .image{
            width: 347px;
            height: 244px;
        }
    }
    .rent-2-content .desc-container{
        margin-left: 340px;
    }
    .rent-2-content .desc-container h2{
        margin-top: 0;
    }
    .rent-2-content .desc-container .data-table{
        width: 100%;
        float: none;
        max-width: 100%;
        margin-bottom: 10px;
    }
    .params-item{
        .text-2 + br{
            display: none;
        }
        .btn{
            margin-top: -19px !important;
            position: relative;
            margin-left: 14px;
            float: right;
        }
    }
    
}
@media screen and (max-width:1120px){
    .stock-content .stock-item .text{
        padding-right: 0;
    }
    .stock-content .stock-item .text .date-stock{
        position: static;
    }
    .stock-content .stock-item .text .date-stock .date-span{
        display: inline-block;
        margin-bottom: 10px;
        font-size: 19px;
    }
    .news-content .news-item{
        width: 30%;
        &:nth-child(4n+1){
            clear: none;
        }
        &:nth-child(3n+1){
            clear: both;
        }
        .name{font-size: 18px;}
    }
    .header__contacts{
        clear: both;
        float: none;
        text-align: right;
        padding-bottom: 10px;
    }
    .breadcrumbs{
        margin-top:30px;
    }
    .header__right-btns{
        float:right;
    }
    .hm-actions-slider__title{
        font-size: 36px;
    }
    .newsWrap .lang + .uk-float-right{
        position: relative;
        z-index: 2;
    }
    .screen-news__title{
        font-size: 28px;
    }
    .newsWrap .screen-news__right{
        padding: 45px 0px 45px 45px;
    }
    #gallery .grid-gallery.grid-image .grid-image__inner p{
        font-size: 13px;
    }
    #gallery .grid-gallery.grid-image:hover .grid-image__inner{
        p{
            font-size: 12px;
            line-height: 15px;
        }
        &:after{
            content:'';
            display: block;
            position: absolute;
            bottom:18px;
            left: 0;
            width:100%;
            height:40px;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+98&0+0,1+82 */
background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 82%, rgba(255,255,255,1) 98%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,1) 98%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,1) 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

        }
        &:before{
            content:'';
            position: absolute;
            z-index: 1;
            height:20px;
            left:0;
            bottom:0px;
            width:100%;
            background: white;
            
        }
    }
    .service-slider .buttons-service{
       margin-left: 0;
        clear: both;
        margin-top:0;
    }
    .service-slider .service-slide .text-slider{
        max-width: 100%;
        width:100%;
        float:none;
        margin-bottom: 20px;
        padding-top: 20px;
    }
}
@media screen and (max-width:1040px){
    .buttons-group{
        &>a{
            padding: 25px 0 19px;
            font-size: 14px;
        }
    }
    .rent-categories>a{
        font-size: 12px;
        line-height: 14px
    }
    .newsWrap .header__left{
        width: 38%;
    }
    .newsWrap .screen-news__left{
        width:40%;
    }
    .contacts-block{
        width: 317px;
        padding: 10px 25px 30px;
    }
    .map-wrap{
        margin-left: 88px;
    }
}
@media screen and (max-width:930px){
    .newsWrap .header__logo img:last-child{
        display: none;
    }
    .infrastructure-content img{
        width:100%;
        margin-bottom: 20px;
    }
    .infrastructure-content .info{
        display: block;
        width:100%;
        margin-bottom: 30px;
        -webkit-columns: 200px auto;
        -moz-columns: 200px auto;
        -o-columns: 200px auto;
        columns: 200px auto;
    }
    .stock-item-content__slider-slide .stock-text{
        margin-right: 0;
    }
    .stock-item-content__slider-slide .form{
        position: static;
        width:auto;
    }
    .stock-item-content__slider-slide .stock-text__inner{
        position: static;
        padding-right: 0;
    }
    .stock-item-content__slider-slide .stock-text{
        height: auto;
    }
}
@media screen and (max-width:900px){
    .partners{
        margin-top: 57px;
        margin-bottom: 60px;
        margin-left: 0;
        position: relative;
    }
    .blog-item .hm-action-box.prev-item{
        margin-left:-79px;
    }
    .blog-item .hm-action-box__right{
        margin-right: 24px;
    }
    .stock-item-content__slider .owl-nav>div.owl-prev{
        left: -63px;
    }
    .stock-item-content__slider .owl-nav>div.owl-next{
        right: -63px;
    }
    .hm-service.uk-grid-width-large-1-3>*{
        width:100%;
    }
    .container-content{
        padding: 0 30px;
    }
    .uk-grid-width-large-1-3>* {
        width: 100%;
    }
    .ratification-slides img{
        margin-right: 30px;
        max-width: 200px;
    }
    .ratification-slides .owl-dots{
        left:18px;
    }
    .one-half-white:before{
        display: none;
    }
    .ratification-slides .text{
        padding:20px;
        padding-right: 0;
    }
    .one-half-white{
        .content .container-content{
            overflow: visible;
        }
    }
    .ratification-slides{
        .slide>.container-content{
            padding-right: 0;
        }
    }
}
@media screen and (max-width:840px){
    #conditions-map .modal-inner{
        width: 500px;
    }
    .hm-actions-slider__caption{
        max-width: 80%;
    }
    .middle-content-page{
        margin-top:20px;
    }
    .params-item .btn{
        margin-top: 5px !important;
        margin-left: 0;
    }
    .params-item .text-2+br {
        display: block;
    }
    .newsWrap .header__left{
        width: 32%;
    }
    .newsWrap{
        .uk-flex{
            display: block;
        }
        .screen-news__left{
            width: 100%;
        }
        .screen-news__right{
            width:100%;
            &:after{
                 display: none;
            }
        }
    }
    .header__right:before{
        display: none;
    }
    .header__logo img:last-child{
        display: none;
    }
    .newsWrap .header__right .btn {
        border: 1px solid #fff;
        border: 1px solid rgba(255,255,255,.39);
        color:white;
    }
    .newsWrap .header__contacts{
        color:white;
    }
    .newsWrap .btn:after, .newsWrap .btn:before {
        border-color: #fff!important;
    }
    .newsWrap .screen-news__right{
        padding:45px;
    }
    .footer-type-2{
        background: white;
        overflow: hidden;
        height: auto;
        .right{
            text-align: center;
            float:none;
            width:100%;
            .copy{
                position: static;
                width:100%;
                clear: both;
            }
        }
    }
    .footer-type-2 .social{
        position: static;
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
    .footer-3{
        .left, .right{
            width:100%;
            float:none;
            text-align: center;
        }
    }
    .news-content .news-item{
        width: 46%;
        margin: 0 2% 45px;
        &:nth-child(3n+1){
            clear: none;
        }
        &:nth-child(2n+1){
            clear: both;
        }
        .name {
            font-size: 15px;
        }
        .text{
            font-size: 13px;
            line-height: 16px;
        }
    }
    .articles-content .article-item .image{
        float:none;
    }
    .articles-content .article-item .text{
        margin-left: 0;
        clear: both;
        margin-top: 15px;
    }
}

@media screen and (max-width:680px){
    .rent-2-content .images{
        width:100%;
        height:auto;
        float:none;
        margin-bottom: 15px;
        .image{
            width:100%;
            height:0%;
            padding-top:60%;
        }
    }
    
    .stock-content .stock-item .image{
        float:none;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .stock-content .stock-item .text{
        margin-left: 0;
    }
    .rent-2-content>div{
        padding: 40px 0;
    }
    .rent-2-content{
        margin-top: -40px;
    }
    .fullScreenImage{
        display: none;
    }
    .rent-2-content .desc-container{
        margin-left:0;
        padding-left: 0;
    }
    .buttons-conditions{
        &>a{width:48%}
    }
}
@media screen and (max-width:640px){
    #conditions-map .modal-inner{
        width: auto;
    }
    .slider-control-block{
        width:100%;
        margin-bottom: 11px;
        padding-bottom: 60px;
        height:auto;
    }
    .partners-slider .partner-item{
        height: 184px;
    }
    .partners-slider{
        margin-left: 0;
    }
    .content-blog-item .comments .comments-list .comment-item-header span{
        margin-right: 15px;
    }
    .content-blog-item .comments .comments-list .comment-item p{
        display: block;
        clear: both;
        padding-top:10px;
    }
    .content-blog-item .comments .comments-list .comment-item .user-icon{
        width: 45px;
        height: 45px;
        left: -45px;
        position: static;
        float: left;
        margin-right: 10px;
    }
    .content-blog-item .comments .comments-list .comment-item{
        padding: 28px 0 12px 0px;
    }
    .content-blog-item .comments .comments-list .comment-item-header .dop-info{
        margin-left:0;
    }
    .blog-item .hm-action-box.prev-item {
        margin-left: -49px;
        width: 0;
        padding-left: 0;
        padding-right: 38px;
        height: 50px;
    }
    .hm-actions-slider__title+p{
        margin-bottom: 40px;
    }
    .content-blog-item{
        &>.container-content{
            padding:0;
        }
    }
    .content-blog-item .item-content-inner{
        padding: 10px 20px;
    }
    .content-blog-item .item-content__body .item-content-inner{
        padding: 20px 20px;
    }
    .content-blog-item .item-content__header{
        .user{
            float:none;
            text-align: center;
            span{
                margin-left:0;
                font-size: 15px;
            }
        }
        .user-icon{
            width:150px;
            height:150px;
            display: block;
            float:none;
            margin:0 auto;
            margin-bottom:30px;
        }
        .dop-info{
            float:none;
            text-align: center;
            margin-top: -10px;
            margin-bottom: 10px;
            &>div{
                float:none;
                margin: 0 10px;
                display: inline-block;
            }
        }
    }
    .content-blog-item .comments .textarea{
        margin-right: 0;
    }
    .content-blog-item .comments .btn{
        position: static;
    }
    .blog-item{
        .hm-actions-slider__caption + .hm-action-box{
            width:0px;
            padding-right: 0;
            margin-right: -54px;
            .hm-action-box__right{
                width: 0;
                padding-left: 0;
                padding-right: 38px;
                height: 50px;
                margin-top: 42px;
            }
        }
    }
    .stock-item-content__slider .owl-nav>div.owl-prev {
        left: -36px;
    }
    .stock-item-content__slider .owl-nav>div.owl-next {
        right: -36px;
    }
    .blog-content .blog-item{
        width:98%;
    }
    .pagination{
        padding-bottom: 40px;
    }
    .infrastructure-content{
        margin-bottom: 70px;
    }
    .vacancy-slider .vacancy-slide{
        margin-left: 50px;
    }
    .header .lang {
        float: right;
        margin-bottom: 5px;
        margin-left: 1px;
    }
    .no-paddings-on-phones{
        .breadcrumbs{
            &>.container-content{
                padding:0;
            }
        }
        .content{
            &>.container-content{
                padding:0;
            }
        }
    }
    .ratification-slides .slide{
        &>.container-content>.container-content{
            text-align: center;
            display: block;
            img{
                margin-bottom: 20px;
                display: inline-block;
                width:90%;
                max-width: 300px;
                margin-right: 0;
            }
            .text{
                clear: both;
                border: 10px solid #f2f2f2;
                padding: 20px;
                &:before, &:after{
                    display: none;
                }
            }
        }
    }
    .ratification-slides .slide>.container-content{
        padding-left:0;
    }
    .newsWrap{
        .lang{
            float:right;
        }
    }
    .ratification-slides{
        padding-bottom: 40px;
    }
    .ratification-slides .owl-dots{
        top:0;
    }
    h1.ghost-text{
        margin-top: 40px;
        margin-bottom: 30px;
        &:before{
            left: -40px;
            font-size: 60px;
            top: -20px;
        }
    }
    .breadcrumbs{
        margin-top: 0;
    }
    .newsWrap .header__left{
        width:auto;
    }
    .header__right-btns .btn{
        float:right;
        margin-left: 3px;
        height: 42px;
        i{
            display: block;
            margin-top: 2px;
            font-size: 18px;
        }  
        span{
            display: none;
        }
        
    } 
    .hm-action-box{
        width:100%;
        
    }
    .hm-actions-slider{
        margin-right: 0;
    }
    .hm-action-box__cont{
        max-width: 100%;
    }
    .hm-action-box__right{
        //right:55px;
    }
    .header__contacts{
        display: none;
    }
    .main-slider .owl-controls{
        bottom:-60px;
    }
    .hm-actions-slider{
        padding: 27px 0 70px 20px;
    }
    .main-slider .owl-controls{
        left:10px;
    }
    .hm-actions-slider__title{
        font-size: 24px;
        margin-bottom: 0;
        & + p{
            margin-top:0;
        }
    }
    .menu-block .header__contacts{
        display: block;
    }
    .menu-block .header__contacts{
        margin-right: -100px;
        margin-top: 50px;
    }
    .menu-block .lang{
        float: right;
        position: relative;
        top: 52px;
        .btn{
            min-width: 42px;
        }
        & + .uk-float-right{
            float: right;
            margin-top: -49px;
        }
    }
    .menu-block .header__right-btns .btn:last-child{
        min-width: 42px;
    }
    .uk-grid>*{
        padding-left:15px;
    }
    .uk-grid{
        margin-left:-15px;
    }
    .content h1{
        font-size: 23px;
    }
}
@media screen and (max-width:520px){
    .hm-actions-slider__caption{
        max-width: 100%;
    }
    .news-content .news-item{
        width:96%;
        
    }
    .articles-content{
        margin-bottom: 20px;
    }
    .articles-content .article-item .image{
        width:100%;
    }
    .screen-news-box-item__img{
        height: 179px;
    }
    .newsWrap .screen-news__right{
        padding: 25px;
    }
    .buttons-group>a{
        display: block;
        width:100%;
        margin-bottom: 5px;
    }
    .middle-content-page{
        margin-top:0;
    }
    .contacts-block{
        position: static;
        transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        width:auto;
        padding:10px 30px 20px 30px;
    }
    .map-wrap{
        margin-left:0;
        margin-right:0;
    }
    .map{
        margin-bottom: 0;
    }
}
@media screen and (max-width:510px){
    .service-slider .buttons-service{
        &>a{
            width: 100%;
        }
    }
    .contents-text .info-item-block{
        padding: 20px 10px 20px 70px;
    }
    //.stock-item-content__slider .owl-nav{
    //    text-align: center;
    //   &>div {
    //        position: static;
    //        margin:0 5px;
    //        margin-top: 15px;
    //        display: inline-block;
    //    } 
    //}
    .stock-item-content__slider .owl-nav{
       &>div {
            width:36px;
           top:200px;
           &.owl-next{
               
           }
           &.owl-right{
               
           }
        } 
    }
    .header__left>*{
        margin-left:0;
    }
    .header .lang .btn, .header__right-btns .btn{
        min-width: 42px;
    }
    .header__right-btns{
        margin-left:5px;
    }
    .menu-block .header__contacts{
        display: none;
    }
    .menu-block .lang+.uk-float-right{
        margin-top:0;
    }
    .menu-block .lang{
        top:0;
    }
    .rent-categories>a{
        width:48%;
    }
    .modal .modal-inner{
        width:auto;
        padding:15px 20px;
    }
    .modal .title.ghost-title:before{
        font-size: 52px;
        left: -13px;
        top: 22px;
    }
    .fancybox-wrap{
        width:90% !important;
        left:5% !important;
    }
    .fancybox-inner{
        width:auto !important;
    }
    .buttons-conditions>a .name{
        font-size: 14px;
    }
    .hm-actions-slider__title{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
}
@media screen and (max-width:461px){
    .auth-block .form-block{
        padding: 30px 9%;
        width: 80%;
    }
    #gallery .grid-gallery.grid-image .grid-image__inner{
        max-height: 70%;
    }
    #gallery .grid-gallery{
        padding-bottom: 100px;
    }
    .infrastructure-content .links{
        position: static;
        margin-bottom: 20px;
    }
}
@media screen and (max-width:420px){
    .content-blog-item .comments .btn{
        width:100%;
    }
    .content-blog-item .item-content{
        margin-bottom: 30px;
    }
    .stock-content .stock-item .image{
        width:100%;
    }
    .header .lang{
        float:right;
        margin-bottom: 5px;
    }
    #gallery{
        margin-top:-15px;
    }
    .hm-actions-slider__title{
        font-size: 20px;
        word-break: break-word;
    }
    .hm-service__item{
        padding: 20px 25px;
    }
    .menu-block .header__logo img{
        display: none;
    }
    .menu-btn.close-menu{
        padding-top:12px;
    }
    .menu-list li a{
        font-size: 17px;
        line-height: 29px;
    }
    .newsWrap .screen-news__left{
        padding: 20px;
    }
    .screen-news__title{
        font-size: 20px;
    }
    
    .buttons-conditions>a .name{
        font-size: 12px;
    }
}
@media screen and (max-width:390px){
    .header__logo img{
        width:50px;
    }
    .header{
        height: auto;
        padding: 25px 0 10px 0;
    }
}
@media screen and (max-width:351px){
    //.breadcrumbs{
    //    margin-top: 30px;
    //}
    .container-content{
        padding:0 15px;
    }
    .blog-item .hm-action-box__right{
        margin-right: 32px;
    }
    .blog-item .hm-action-box.prev-item{
        margin-left: -41px;
    }
}
@media screen and (max-width:332px){
    
    
    .footer{ 
        .left, .right{
            width:100%;
            float:none;
            text-align: center;
        }
    }
    .social{
        width:100%;
        text-align: center;
    }
    .ratification-slides .owl-dots{
        left:4px;
    }
}
@media screen and (max-width:321px){
    .header__logo img{
        display: none;
    }
    .header__left>:first-child{
        margin-top: 13px;
    }
}
@media (min-width: 520px){
    .uk-grid-width-large-1-3>* {
        width: 33.333%;
    }
}