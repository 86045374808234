// Name:            Breadcrumb
// Description:     Defines styles for a breadcrumb navigation
//
// Component:       `uk-breadcrumb`
//
// States:          `uk-active`
//
// Markup:
//
// <!-- uk-breadcrumb -->
// <ul class="uk-breadcrumb">
//     <li><a href=""></a></li>
//     <li><span></span></li>
//     <li class="uk-active"><span></span></li>
// </ul>
//
// ========================================================================


// Variables
// ========================================================================

@breadcrumb-font-size:                          1rem;

@breadcrumb-divider:                            "/";
@breadcrumb-divider-margin:                     8px;
@breadcrumb-disabled-color:                     #999;


/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */

/*
 * 1. Remove default list style
 * 2. Remove whitespace between child elements when using `inline-block`
 */

.uk-breadcrumb {
    /* 1 */
    padding: 0;
    list-style: none;
    /* 2 */
    font-size: 0.001px;
    .hook-breadcrumb;
}


/* Items
 ========================================================================== */

/*
 * Reset whitespace hack
 */

.uk-breadcrumb > li {
    font-size: @breadcrumb-font-size;
    vertical-align: top;
}

.uk-breadcrumb > li,
.uk-breadcrumb > li > a,
.uk-breadcrumb > li > span { display: inline-block; }

.uk-breadcrumb > li:nth-child(n+2):before {
    content: @breadcrumb-divider;
    display: inline-block;
    margin: 0 @breadcrumb-divider-margin;
    .hook-breadcrumb-divider;
}

/*
 * Disabled
 */

.uk-breadcrumb > li:not(.uk-active) > span { color: @breadcrumb-disabled-color; }


// Hooks
// ========================================================================

.hook-breadcrumb-misc;

.hook-breadcrumb() {}
.hook-breadcrumb-divider() {}
.hook-breadcrumb-misc() {}