/* font-family: "LatoRegular"; */
@font-face {
    font-family: "LatoRegular";
    src: url("@{font}/LatoRegular/LatoRegular.eot");
    src: url("@{font}/LatoRegular/LatoRegular.eot?#iefix")format("embedded-opentype"),
    url("@{font}/LatoRegular/LatoRegular.woff") format("woff"),
    url("@{font}/LatoRegular/LatoRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "LatoBold"; */
@font-face {
    font-family: "LatoBold";
    src: url("@{font}/LatoBold/LatoBold.eot");
    src: url("@{font}/LatoBold/LatoBold.eot?#iefix")format("embedded-opentype"),
    url("@{font}/LatoBold/LatoBold.woff") format("woff"),
    url("@{font}/LatoBold/LatoBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "LatoItalic"; */
@font-face {
    font-family: "LatoItalic";
    src: url("@{font}/LatoItalic/LatoItalic.eot");
    src: url("@{font}/LatoItalic/LatoItalic.eot?#iefix")format("embedded-opentype"),
    url("@{font}/LatoItalic/LatoItalic.woff") format("woff"),
    url("@{font}/LatoItalic/LatoItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "LatoLight"; */
@font-face {
    font-family: "LatoLight";
    src: url("@{font}/LatoLight/LatoLight.eot");
    src: url("@{font}/LatoLight/LatoLight.eot?#iefix")format("embedded-opentype"),
    url("@{font}/LatoLight/LatoLight.woff") format("woff"),
    url("@{font}/LatoLight/LatoLight.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "LatoBoldItalic"; */
@font-face {
    font-family: "LatoBoldItalic";
    src: url("@{font}/LatoBoldItalic/LatoBoldItalic.eot");
    src: url("@{font}/LatoBoldItalic/LatoBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("@{font}/LatoBoldItalic/LatoBoldItalic.woff") format("woff"),
    url("@{font}/LatoBoldItalic/LatoBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "LatoLightItalic"; */
@font-face {
    font-family: "LatoLightItalic";
    src: url("@{font}/LatoLightItalic/LatoLightItalic.eot");
    src: url("@{font}/LatoLightItalic/LatoLightItalic.eot?#iefix")format("embedded-opentype"),
    url("@{font}/LatoLightItalic/LatoLightItalic.woff") format("woff"),
    url("@{font}/LatoLightItalic/LatoLightItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}



.font {
	  font-family: "LatoRegular";
}
.font-l {
  font-family: "LatoLight";
}
.font-b {
    font-family: "LatoBold";
}
