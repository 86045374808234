.rangeslider,
.rangeslider__fill {
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.rangeslider {
  background: #eeeeee;
  position: relative;
}

.rangeslider--horizontal {
  height: 4px;
  width: 100%;
}

.rangeslider--vertical {
  width: 4px;
  min-height: 150px;
  max-height: 100%;
}

.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeslider__fill {
  background: #1c84ba;
  position: absolute;
}
.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}
.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%;
}

.rangeslider__handle {
  background: #1c84ba;
  cursor: pointer;
  display: inline-block;
  width: 17px;
  height: 17px;
  position: absolute;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
    &:after{
        content: attr(data-value);
        position: absolute;
        font-family: LatoLightItalic;
        font-size: 14px;
        top: 19px;
        left: 50%;
        display: block;
        color: #232323;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
    }
}
.rangeslider--horizontal .rangeslider__handle {
  top: -7px;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}
.rangeslider--vertical .rangeslider__handle {
  left: -7px;
  touch-action: pan-x;
  -ms-touch-action: pan-x;
}