.btn, .header, .hm-action-box__cont, .hm-service__item, .screen-news__left, .screen-news__right, .uk-badge-notification, .uk-container, .uk-dropdown, .uk-dropdown-blank, .uk-modal-dialog, .uk-overlay-area-content, .uk-scrollable-box, .uk-thumbnail, [class*=uk-height] {
    box-sizing: border-box;
}
.hm-service__list, .list-reset, .uk-breadcrumb, .uk-comment-list, .uk-comment-list .uk-comment+ul, .uk-grid, .uk-list, .uk-list ul, .uk-nav, .uk-nav ul, .uk-navbar-nav, .uk-subnav, .uk-switcher, .uk-tab, .uk-thumbnav, ul.bxslider {
    list-style: none;
}
h1, h2, h3, h4, h5, h6{
    font-family: LatoRegular;
}
.first-screen {
    //background-color: #7489a5;
}
.first-screen, .first-screen__bg-item {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}
.mainWrapper {
    margin: 0 auto;
    min-height: 100%;
    position: relative;
    text-align: left;
    overflow: hidden;
}
.first-screen__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.bx-wrapper {
    position: relative;
    padding: 0;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}
.bx-wrapper {
    margin: 0;
    background: 0 0;
    border: none;
    box-shadow: none;
}
.first-screen__bg-item {
    width: 100vw!important;
    height: 100vh!important;
}
.header {
    padding: 25px 0;
    color: #fff;
    height: 100px;
    position: relative;
    z-index: 1;
}
.container-content {
    margin: 0 auto;
    max-width: none;
    padding: 0 55px;
    overflow: hidden;
}

[class*=uk-grid-width]>* {
    box-sizing: border-box;
    width: 100%;
}
.uk-float-left {
    float: left;
}
.header__left>:first-child {
    margin-left: 0;
}
.menu-btn {
    display: inline-block;
    cursor: pointer;
    &__close{
        background: url(/img/close.png);
        width: 12px;
        height: 14px;
        border: none;
        margin-right: 5px;
        position: relative;
        top: 1px;
        outline: none;
    }
}
.uk-grid, ol>li>ol, ol>li>ul, ul>li>ol, ul>li>ul {
    margin: 0;
}
.hm-service__title {
    border-bottom: 1px solid rgba(255,255,255,.39);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.uk-grid>*>:last-child {
    margin-bottom: 0;
}
.hm-service__list {
    padding: 0;
    margin: 0;
}
.hm-service__list li {
    margin: 5px 0;
    line-height: normal;
}
.hm-service__item {
    padding: 30px 50px;
    border: 1px solid #fff;
    border: 1px solid rgba(255,255,255,.39);
    border-left: none;
}
.hm-service{
    a.hm-service__item{
        color: inherit !important;
        transition: all 0.3s;
        text-decoration: none !important;
        &:hover{
            background: rgba(255,255,255,0.3);
         } 
    }
}
.uk-grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
}
.uk-grid {
    margin-left: -25px;
}
.uk-grid-collapse {
    margin-left: 0;
}
.menu-btn__bar {
    width: 20px;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    background: 0 0;
    outline: 0!important;
    border: none;
    padding: 0;
    cursor: pointer;
}
.uk-grid-width-large-1-3>* {
    width: 33.333%;
}
.menu-btn__txt {
    display: inline-block;
    vertical-align: middle;
    margin: 0 -4px 0 -1px;
    text-transform: uppercase;
}
.btn, .btn__line, .first-screen__wrap {
    position: relative;
}
.first-screen__wrap {
    min-height: 100vh;
    width: 100%;
    z-index: 2;
}
.menu-btn{
    cursor: pointer;
}
.menu-btn__line {
    display: block;
    height: 3px;
    color: white;
    background: white;
    margin: 2px 0;
    width: 100%;
}
.menu-btn__line:last-child {
    width: 60%;
}
.header__left>* {
    margin: 0 10px;
}
.header__logo img {
    margin: 0 5px;
}
.header__logo img:last-child {
    margin-top: -3px;
}
.header__logo img {
    margin: 0 5px;
}
.new-context {
    display: table;
}
.btn, .btn__line, .first-screen__wrap {
    position: relative;
}
.btn, .icon-circle {
    text-align: center;
}
.btn, .header__phone {
    text-decoration: none!important;
}
.header__phone{
    transition: all 0.3s;
}
.hm-service__title, .screen-news__title {
    text-transform: uppercase;
}
.header .lang {
    display: inline-block;
    color: inherit;
}
.btn_white, .btn_white:hover {
    color: #fff;
}
.header .lang .btn {
    margin-left: 3.5px;
}
.btn {
    font-size: 1rem;
    padding: 10px;
    min-width: 56px;
    transition: all .4s ease;
    box-shadow: 0 0 0 0 none;
    display: inline-block;
    border: 1px solid #b4cadc;
}
.btn_white {
    border: 1px solid #fff;
    border: 1px solid rgba(255,255,255,.39);
}
.btn:after, .btn:before {
    bottom: -1px;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    display: block;
    border-color: inherit;
}
.btn:before {
    right: 80%;
    transition: width .2s ease 0.4s;
    border-bottom: 2px solid;
}
.btn:after {
    left: -1px;
    transition: width .2s ease .0s,height .2s ease .2s,border 1s .2s ease;
    border-left: 0px solid;
    border-top: 0px solid;
}
.btn:hover:after {
    transition: width .2s ease .4s,height .2s ease .2s,border .1s .2s ease;
    //border-left: 2px solid;
    //border-top: 2px solid;
}
.btn:hover:after{
    border-left: 2px solid;
    border-top: 2px solid;
}
.btn.btn_active:before, .btn:hover:before {
    width: 20%;
    
    transition: width .2s ease;
}
.btn.btn_active:after, .btn:hover:after {
    height: 100%;
    width: 50%;
}
.header__contacts {
    float: left;
}
.header__phone {
    font-size: 19px;
    color: inherit;
}
.header__right-btns {
    display: block;
    float: left;
    margin-left: 15px;
}
.header__right-btns .btn {
    display: block;
    margin-bottom: 14px;
}
.header__right-btns .btn:last-child {
    margin-bottom: 0;
}
.btn__txt {
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
}
.btn__line {
    display: inline-block;
    height: 1px;
    margin-left: 3px;
    margin-right: 8px;
    width: 22px;
    vertical-align: middle;
    background: currentColor;
    &:after, &:before {
        height: 100%;
        background: inherit;
        content: '';
        position: absolute;
        top: 0;
        display: block;
    }
    &:before {
        width: 2px;
        right: -10px;
    }
    &:after {
        width: 4px;
        right: -6px;
    }
}
.btn_active-right:before {
    right: auto;
    left: 80%;
}
.btn_active-right.btn_active:before, .btn_active-right:hover:before {
    width: 20%;
    border-bottom: 2px solid;
}
.hm-action-box__cont .btn:hover{
    color:  #07D;
}
.container-content:after, .container-content:before {
    content: "";
    display: table;
}
.btn_active-right:after {
    left: auto;
    right: -1px;
    border-right: 2px solid;
    border-left:0 !important;
    border-left: none;
}
.btn_active-right.btn_active:after, .btn_active-right:hover:after {
    height: 100%;
    width: 50%;
    border-right: 2px solid;
        border-top: 2px solid;
}
.container-content {
    margin: 0 auto;
    padding: 0 55px;
}
.first-screen__cont, .first-screen__cont .container-content {
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    -o-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
}
.uk-flex {
    display: -ms-flexbox;
    display: flex;
}
.uk-flex-inline>*, .uk-flex>* {
    -ms-flex-negative: 1;
}
.first-screen__cont-wrap {
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    -o-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    border-left: 1px solid #e5e5e5;
    margin-left: -1px;
}
.uk-flex-bottom {
    -ms-flex-align: end;
    align-items: flex-end;
    width:100%;
}
.hm-actions-slider {
    color: #fff;
    padding: 66px 0 140px 50px;
    margin-right: -55px;
    position: relative;

}
.hm-actions-slider__caption {
    font-size: 18px;
    max-width: 615px;
}
.hm-actions-slider__title {
    font-size: 43px;
    text-transform: uppercase;
    margin: 0 0 10px;
}
.hm-action-box {
    position: relative;
    padding-right: 55px;
    background: #fff;
    background: rgba(255,255,255,.66);
    color: #191919;
    line-height: normal;
}
.hm-action-box__right {
    width: 55px;
    height: 100%;
    background: #1c84ba;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
.hm-service {
    background-color: rgba(255,255,255,.25);
    color: #fff;
    font-size: 15px;
}
.uk-grid-collapse {
    margin-left: 0;
}
.hm-action-box__cont {
    padding: 15px 28px 20px;
    max-width: 366px;
    font-family: LatoLight;
}
.hm-action-box__right-txt {
    transform: rotate(-90deg);
    transform: translate(-50%,-50%) rotate(-90deg);
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
}
.uk-display-inline-block {
    display: inline-block!important;
    max-width: 100%;
}
.screen-news-box__title {
    font-size: 19px;
}
.screen-news-box .ta-just>a{
    color: #000;
    transition: all 0.3s;
    &:hover{
        color:#1c84ba;
    }
}
.hm-action-box__title {
    font-family: LatoRegular;
    font-size: 20px;
}
.hm-action-box__cont p {
    margin: 10px 0;
}
.hm-action-box__cont .btn {
    width: 100%;
    max-width: 185px;
    margin-top: 5px;
}
.uk-flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.uk-flex-space-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.first-screen__cont-wrap>div{
    width:100%;
}
h1, h2, h3, h4, h5, h6{
    color:inherit;
    line-height: normal;
}
.btn_active-left.btn_active:before, .btn_active-left:hover:before {
    width: 20%;
    border-bottom: 2px solid;
}
.uk-text-break, body {
    word-wrap: break-word;
}
.btn__txt {
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
}
.btn_dark .btn__line {
    background: #1c84ba;
}
.btn_active-left.btn_active:after, .btn_active-left:hover:after {
    height: 100%;
    width: 50%;
    border-left: 2px solid;
    border-top: 2px solid;
}
.main-slider{
    display: none;
    .owl-controls {
        position: absolute;
        left: -27px;
        bottom: -90px;
        padding: 20px 30px;
        float: left;
        .owl-nav{
            &>div{
                position: absolute;
                top: 21px;
                &.owl-prev{
                    left:0;
                }
                &.owl-next{
                    right:0;
                }
            }
        }
    }
}
.owl-dots{
    margin-left:5px;
    .owl-dot{
        width:11px;
        height:11px;
        background:#807a78;
        border-radius: 50%;
        margin-right: 5px;
        float:left;
        transition: all 0.3s;
        &.active{
            background: #1c84ba;
        }
    }
}
body{
    background-size: cover;
    height: auto;
}
.owl-nav{
    font-size: 0;
    &>div{
        background-image: url(/img/sprite.png);
        background-repeat: no-repeat;
        width:25px;
        height:7px;
        background-position: 0px 0px;
        &.owl-next{
            background-position: 0px -7px;
        }
    }
}
.left{
    float:left;
}
.right{
    float:right;
}
.social {
    font-size: 18px;
    margin: 0 -10px;
    display: inline-block;
    &__item{
        margin: 10px;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: middle;
        transition: all 0.3s;
        zoom: 1;
        &:hover{
            color:#1c84ba;
        }
    }
}
.copy {
    font-size: 12px;
    line-height: 25px;
}
footer{
    position: relative;
}
.copy, .footer {
    padding: 8px 0;
    color:white;
    position: relative;
    
    a{
        text-decoration: none;
        color:white;
    }
}
.body-bg-1{
    background-size:cover;
    background-position: center;
}
.body-bg-2{
    background:#f7f7f7;
}
.one-half-white{
    &:before{
        content: '';
        display: block;
        position: fixed;
        top:0;
        bottom:0;
        right:0;
        width:50%;
        background:white;
    }
}
.screen-news__title-sup {
    font-size: 18px;
    line-height: normal;
}
.copy, .screen-news-box__link-all, .screen-news__left {
    font-family: LatoLight;
}
.screen-news__title {
    font-size: 36px;
    line-height: normal;
    font-weight: bold;
}
.screen-news-box {
    z-index: 2;
    border-bottom: 1px solid #eee;
    margin-bottom: 18px;
    position: relative;
}
.ta-just {
    text-align: justify;
    &:after{
        content: '';
        width: 100%;
        display: inline-block;
        line-height: 0;
    }
}
.menu-block{
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    display: none;
    z-index: 10;
    background: rgba(255, 255, 255, 1);
    padding-top:25px;
    .btn_white, .btn_white:hover{
        color:black;
        border-color:#c9dbea !important;
        &:before, &:after{
            border-color:#1c84ba !important;
        }
    }
    .lang{
        float:left;
        .btn_white{
            margin-left:3.5px;
        }
    }
    .social{
        text-align: center;
        margin:0 auto;
        float:none;
        display: block;
        position: absolute;
        bottom:15px;
        left:0;
        right:0;
        &__item{
            color:#454646;
            &:hover{
                color:#1c84ba;
            }
        }
    }
}
.header__right-btns .btn i{
    display: none;
}
.menu-list{
    padding: 0;
    margin: 0;
    font-family: LatoLight;
    font-size: 23px;
    position: absolute;
    left: 0;
    list-style: none;
    text-align: center;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 60px;
    top: 90px;
    overflow: auto;
    ul{
        padding: 0;
        margin: 0;
        list-style: none;
    }
    li{
        display: block;
        a{
            text-decoration: none;
            color:#323232;
            transition: all 0.3s;
            line-height: 36px;
            &:hover{
                color:#1c84ba;
            }
        }
    }
}
.bg-overlay:before{
    content:'';
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display: block;
    background:black;
    opacity: 0.27;
}

.newsWrap{
    overflow: hidden;
    .header__right-btns .btn:last-child{
        display: none;
    }
    .header__contacts{
        position: relative;
        color:black;
    }
    .header__left{
        width: 34%;
    }
    .header__right{
        padding-left: 22px;
        position: relative;
        &:before{
            content: '';
            position: fixed;
            background: #fff;
            top: 0;
            width: 4000px;
            margin-left: -25px;
            height: 100vh;
        }
        .btn{
            border: 1px solid #b4cadc;
            color: #191919;
        }
    }
    .container-content{
        overflow: visible;
    }
    .btn:after, .btn:before {
        border-color: #1c84ba!important;
    }
    
}
.newsWrap .screen-news__left {
    width: 34%;
    padding: 40px;
    color: #fff;
    font-size: 15px;
}
.newsWrap .screen-news__right {
    height: 100%;
    background: #fff;
    width: 66%;
    color:black;
    padding: 45px;
    position: relative;
    &:after{
        content: '';
        position: absolute;
        background: #fff;
        top: 0;
        left: 0;
        width: 4000px;
        height: 100vh;
    }
}
.screen-news-box__link-all {
    text-decoration: none;
    color: inherit;
    font-size: 14px;
    transition: all 0.3s;
    text-decoration: none !important;
}
.icon-circle {
    border: 1px solid;
    border-radius: 50%;
    width: 1em;
    height: 1em;
}
[class*=uk-grid-width]>* {
    box-sizing: border-box;
    width: 100%;
}
.uk-grid {
    margin-left: -35px;
}
.screen-news-box-item__link {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}
.screen-news-box-item__img {
    height: 109px;
    position: relative;
    margin-bottom: 10px;
    &:before{
        content:'';
        position: absolute;
        top:0;
        left:0;
        right:0;
        opacity: 0;
        transition: all 0.3s;
        bottom:0;
        background:rgba(0,0,0,0.5);
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.screen-news-box-item__title {
    position: relative;
    font-size: 15px;
    margin-bottom: 20px;
}
.screen-news-box-item__img-span {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 3;
}
.screen-news-box-item:hover{
    .screen-news-box-item__img{
        &:before{
            opacity: 1;
        }
    }
    .screen-news-box-item__img-span{
        opacity: 1;
        visibility: visible;
    }
}
.screen-news-box__item{
    position: relative;
    &:hover{
        opacity: 1;
    }
}
.newsWrap .first-screen__cont-wrap{
    position: relative;
    border-bottom: 1px solid #e5e5e5;
}
.footer-type-2{
    position: relative;
    z-index: 3;
    height:25px;
    .container-content{
        position: relative;
        overflow: visible;
        .right{
            position: relative;
            width:100%;
        }
    }
    .copy{
        color: #333;
        position: absolute;
        background: #fff;
        top: -35px;
        right: 0;
        padding-left: 15px;
        a{
            color:#333;
        }
    }
    .social{
        position: absolute;
        left: 50%;
        top: -28px;
        background: white;
        padding: 0 9px;
        margin-left: -65px;
        i{
            color:#cacaca;
        }
    }
}
.no-news-button{
    .header__right-btns .btn:last-child{
        display: none;
    }
}
.breadcrumbs{
    border-top:1px solid rgba(255,255,255,0.27);
    border-bottom:1px solid rgba(255,255,255,0.27);
    padding:17px 0;
    color:white;
    position: relative;
    a{
        color:white;
        opacity: 0.7;
        transition: all 0.3s;
        text-decoration: underline;
        &:hover{
            opacity: 1;
        }
    }
    &.breadcrumbs-dark{
        color:#2f2f2f;
        border-top:1px solid #eeeeee;
        border-bottom:1px solid #eeeeee;
        a{
            color:#2f2f2f; 
        }
    }
}
.color-white{
    color:white;
}
.content{
    h1{
        text-transform: uppercase;
        font-family: "LatoRegular";
        font-weight: normal;
        margin-top:39px;
        margin-bottom: 10px;
        & + p{
            margin-top: 0;
            max-width: 770px;
        }
    }
}
.rent-categories{
    margin:0 -1%;
    &>a{
        display: block;
        float:left;
        width:23%;
        line-height: 27px;
        margin:0 1%;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-family: "LatoRegular";
        font-size: 22px;
        color:white;
        .image{
            width:100%;
            background-size: cover;
            background-position: center;
            padding-top: 150%;
            box-shadow: 0px 0px 16px rgba(0,0,0,0.17);
        }
    }
}
.header-black{
    color:#2f2f2f;
    .menu-btn__line{
        background:black;
    }
    .btn{
        color: #000;
        border-color: #c9dbea!important;
    }
    .btn_white:after,  .btn_white:before,  .btn_white:hover:after,  .btn_white:hover:before {
        border-color: #1c84ba!important;
    }
}
h1.ghost-text{
    margin-top:60px;
    margin-bottom: 75px;
    position: relative;
    span{
        position: relative;
    }
    &:before{
        content: attr(data-title);
        position: absolute;
        font-size: 120px;
        left: -46px;
        top: -45px;
        color: #f1f1f1;
    }
}
.rent-2-content{
    margin-top: -66px;
    &>div{
        padding: 66px 0;
        &:nth-child(2n+2){
            background: white;
        }
    }
    .container-content{
        overflow: visible;
    }
    .images{
        width:447px;
        height:314px;
        float:left;
        position: relative;
        &:hover{
            .fullScreenImage{
                opacity: 1;
            }
        }
        .owl-stage-outer:before{
            content: '';
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
            position: absolute;
            left:0;
            right:0;
            bottom:0;
            height:90px;
            z-index: 1;
        }
        .image{
            width:447px;
            height:314px;
            display: block;
            background-size: cover;
            background-position: center;
        }
        .owl-nav{
            position: absolute;
            bottom:0;
            z-index: 2;
            left: 0;
            right: 0;
            &>div{
                position: absolute;
                bottom:15px;
                &.owl-prev{
                    left:15px;
                }
                &.owl-next{
                    right: 15px;
                }
            }
        }
        .items-cur {
            position: absolute;
            z-index: 4;
            color: white;
            left: 50%;
            bottom: 10px;
            width: 100px;
            margin-left: -50px;
            text-align: center;
        }
        .fullScreenImage{
            cursor: pointer;
            width:40px;
            height:40px;
            background: #1c84ba;
            border-radius: 50%;
            position: absolute;
            top:-18px;
            opacity: 0;
            transition: all 0.3s;
            right:-18px;
            z-index: 2;
            &:after{
                content: '';
                display: block;
                position: absolute;
                width: 14px;
                height: 14px;
                background-image: url(/img/sprite.png);
                background-position: 0px -16px;
                left: 13px;
                top: 13px;
            }
        }
    }
    .desc-container{
        padding-left: 29px;
        margin-left: 447px;
        h2{
            font-size: 22px;
            margin-top: 15px;
        }
        .data-table{
            background: white;
            border-collapse: collapse;
            float:left;
            max-width: 293px;
            margin-right: 25px;
            margin-bottom: 25px;
            td{
                border:1px solid #f4f3f3;
                padding:5px 7px;
                font-family: "LatoLight";
                &:nth-child(1){
                    font-family: "LatoRegular";
                }
            }
        }
        .btn{
            margin-top:10px;
        }
        .params-item{
            display: block;
            float: left;
            margin-bottom: 25px;
        }
    }
    
    .tags{
        display: table;
        width: 100%;
        &>div{
            max-width: 240px;
            margin-right: 20px;
            margin-bottom: 15px;
            display: inline-block;
            line-height: 14px;
            vertical-align: top;
            span{
                display: block;
                
                
            }
            .icon{
                width:28px;
                margin-right: 10px;
                height:28px;
                float:left;
                border-radius: 2px;
                background-color:#0a2339;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center;
                & + span{
                    display: inline;
                }
            }
        }
    }
    
}
.hm-service.uk-grid.uk-grid-collapse.uk-grid-width-large-1-3{
    margin-left: 0;
}
.text-1{
    text-transform: uppercase;
    font-size: 10px;
    font-family: "LatoLight";
}
.text-2{
    font-size: 22px;
    font-family: "LatoRegular";
}
.body-bg-2 .content{
    .btn{
        color: #000;
        border-color: #c9dbea!important;
    }
    .btn_white:after,  .btn_white:before,  .btn_white:hover:after,  .btn_white:hover:before {
        border-color: #1c84ba!important;
    }
}
.clear{
    width:100%;
    clear: both;
}
.footer-3{
    background:#0a2339;
    padding: 11px 0 7px;
}
.buttons-group{
    width:100%;
    display: table;
    max-width: 1040px;
    margin-top: 40px;
    margin-bottom: 50px;
    &>a{
        display: table-cell;
        float: none;
        width: 33%;
        margin-left: -1px;
        padding: 36px 32px 30px;
        font-size: 18px;
    }
}
.middle-content-page{
    margin-top:100px;
    
    &>.container-content{
        width: 100%;
        &>div{
            width:100%;
            box-sizing: border-box;
        }
    }
}
.fancybox-inner{
    &::-webkit-scrollbar {
        width:3px;
    }
    &::-webkit-scrollbar-track {
        background: #ededed;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
        background: #0099cc;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #00a8e0;
    }
}
input[type="range"]{
    display: none;
}
.modal{
    display: none;
    color:#2f2f2f;
    .title{
        font-size: 22px;
        padding:20px 0 40px 0;
        text-transform: uppercase;
        span{
            position: relative;
        }
        &.ghost-title{
            position: relative;
            &:before{
                content: attr(data-title);
                position: absolute;
                font-size: 70px;
                left: -26px;
                top: 23px;
                color: #f1f1f1;
            }
            .total-text{
                font-size: 18px;
                color:#1c84ba;
                text-transform: uppercase;
                float:right;
                position: relative;
            }
        }
    }
    .modal-inner {
        padding: 15px 35px;
        width:320px;
    }
    button{
       width:100%; 
        font-size: 15px;
        .btn__line{
            height:2px;
        }
    }
    .modal-text{
        font-family: "LatoLight";
        font-size: 15px;
        color:#2f2f2f;
        margin-top:0;
    }
    .map-wrap, .map-box{
        margin:0;
        height:420px;
        width:100%;
    }
}
#conditions-map{
    .modal-inner{
        width:600px;
        //height:600px;
    }
}
.input{
    margin-bottom: 20px;
    .input-title{
        font-size: 15px;
        margin-bottom: 3px;
    }
    .selectric{
        font-family: "LatoLightItalic";
        font-size: 14px;
        .label{
            font-size: 14px;
        }
    }
    input{
        transition: all 0.3s;
    }
    &.error{
        input{
            border: 1px solid #ff8989;
        }
    }
    .error-text{
        color: #ff3b3b;
    }
}
.fancybox-inner{
    overflow-x: hidden !important;
}
.selectric-white-selectric{
    .selectric{
        border:1px solid #eeeeee;
        background:#fff;
        .button{
            background: none; 
        }
    }
}
.selectric-disabled{
    .selectric{
        border:none;
        background:#f8f7f7;
    }
}
.btn_blue{
    background:none;
    outline: none;
    color: #000;
    cursor: pointer;
    border-color: #c9dbea!important;
    &:after, &:before, &:hover:after, &:hover:before {
        border-color: #1c84ba!important;
    }
    .btn__line{
        color:#1c84ba;
    }
}
button{
    font-family: "LatoRegular";
}
.input{
    input, textarea{
        width: 100%;
        border: 1px solid #eee;
        outline: 0;
        display: block;
        box-sizing: border-box;
        padding: 10px 13px;
        transition: all 0.3s;
        font-size: 14px;
        border-radius: 2px;
        font-family: "LatoLightItalic";
        &:focus{
            background:#f8f7f7;
            border:1px solid #f8f7f7;
        }
    }
    textarea{
        height:90px;
        resize: vertical;
    }
    &.input-range{
        .rangeslider{
            margin-top: 12px;
            margin-bottom: 41px;  
        }
    }
}
.buttons-conditions{
    margin: 0 -1%;
    &>a{
        display: block;
        text-decoration: none;
        float:left;
        width:31.3333%;
        margin:2% 1%;
        padding:27px 0;
        transition: all 0.3s;
        color:white;
        text-align: center;
        box-shadow: 0px 0px 0px 1px #888887 inset;
        &:hover{
            background: #e3dedd;
            box-shadow: 0px 0px 0px 1px #e3dedd inset;
            color:#2f2f2f;
        }
        .icon{
            background-image: url(/img/conditions-icon.png);
            background-repeat: no-repeat;
            width:83px;
            height:68px;
            transition: all 0.3s;
            display: inline-block;
            background-position: 0 0;
            
        }
        .name{
            text-transform: uppercase;
            font-size: 16px;
            display: block;
        }
        .icon-cond-1{background-position: 0 0; width:73px;}
        .icon-cond-2{background-position: -84px 0;width: 60px;}
        .icon-cond-3{background-position: -155px 0;width: 57px;}
        .icon-cond-4{background-position: -216px 0;width: 72px;}
        &:hover{
            .icon{
                background-image: url(/img/conditions-icon-hover.png);
            }
        }
    }
}
.ratification-slides{
    padding-bottom: 75px;
    margin-bottom: 36px;
    display: none;
    .container-content{
        overflow: visible;
    }
    .slide{
        overflow: hidden;
    }
    img{
        margin-right: 54px;
        max-width: 330px;
    }
    .items-cur{
        position: static !important;
        font-size: 12px;
        color: #2f2f2f;
        width: auto;
        background: none !important;
        height: auto;
        margin-top: -6px;
        padding: 0px 6px;
    }
    .text{
        border-left:10px solid #f2f2f2;
        padding:36px;
        position: relative;
        color:#2f2f2f;
        .title{
            font-size: 18px;
        }
        p{
            font-family: "LatoLight";
        }
        &:after, &:before{
            content:'';
            position: absolute;
            height:10px;
            width:9999px;
            left: 0;
            background: #f2f2f2;
        }
        &:after{
            bottom:0;
        }
        &:before{
            top:0;
        }
    }
    .owl-nav{
        position: absolute;
        left:50%;
        bottom: 0;
        padding-right: 30px;
        padding-left:30px;
        transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -webkit-transform: translate(-50%, 0%);
        -o-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        &>div{
            position: absolute;
            &.owl-next{
                background-position: -25px -7px;
                right:0;
                &:hover{
                    background-position: -25px -21px;
                }
            }
            &.owl-prev{
                background-position: -25px 0;
                left:0;
                &:hover{
                    background-position: -25px -14px;
                }
            }
        }
        
    }
    .owl-dots{
        position: absolute;
        top:30%;
        left:55px;
        .owl-dot{
            float:none;
            margin-left:0;
            margin-right:0;
            margin-bottom: 5px;
            background:#e1e1e1;
            &.active{
                background:#1c84ba;
            }
        }
    }
}
.gallery-wrap{
    margin-bottom: 50px;
    .btn{
        width:200px;
    }
}
#gallery{
    position: relative;
    margin:0 -8px;
    margin-top: -30px;
    opacity: 0;
    margin-bottom: 15px;
    transition: all 0.3s;
    &.loaded{
        opacity: 1;
    }
    .grid-gallery {
        width: 188px;
        display: block;
        min-height: 100px;
        background: #fff;
        margin: 8px;
        font-size: 12px;
        float: left;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        img{
            max-width: 100%;
            background: #ccc;
            display: block;
        }
        &.grid-video{
            &:before{
                content: '';
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:black;
                opacity:0.23;
            }
            .video-content{
                position: absolute;
                top:50%;
                color:white;
                width:86%;
                text-align: center;
                left:50%;
                transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                font-family: "LatoLight";
                font-size: 14px;
                line-height: 16px;
                .play-button{
                    width: 85px;
                    height:85px;
                    margin:0 auto;
                    background-image: url(/img/video-play.png);
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    &:hover{
                      background-position: 0 -85px;  
                    }
                }
            }
        }
        &.grid-image{
            &:hover .grid-image__inner{
                opacity: 1;
                bottom: 0;
            }
            .grid-image__inner{
                background: white;
                position: absolute;
                bottom:-15px;
                color:#2f2f2f;
                margin: 5%;
                padding: 6% 9%;
                overflow: hidden;
                max-height: 70%;
                opacity: 0;
                transition: all 0.3s;
                .title{
                    font-size: 17px;
                    margin-bottom: 2px;
                }
                p{
                    font-size: 14px;
                    font-family: "LatoLight";
                    margin:2px 0;
                }
            }
        }
    }
}
.center{
    text-align: center;
}
.map-wrap, .map-box{
    height:521px;
}
.map{
    margin-bottom: 45px;
    position: relative;
}
.map-wrap{
    margin-left:211px;
    margin-right:-55px;
}
.contacts-block{
    background:white;
    position: absolute;
    color:#383838;
    width:517px;
    padding:25px 35px 40px 35px;
    top:50%;
    left:0;
    transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    z-index: 2;
    .btn{
        font-size: 15px;
        padding: 11px 20px 9px 20px;
    }
    .subtitle{
        font-size: 11px;
        text-transform: uppercase;
        font-family: "LatoLight";
        margin:0;
        margin-top:14px;
    }
    .info{
        font-size: 15px;
        margin:0;
        a{
            color:#383838;
            text-decoration: none;
        }
    }
}
.vacancy-slider-wrap{
    margin-bottom: 80px;
    position: relative;
    .items-cur{
        position: absolute;
        left:0;
        top:50%;
        font-size: 12px;
        margin-top:-10px;
        .current{
            color:#2f2f2f;
            font-size: 18px;
        }
        .total{
            font-family: "LatoLight";
            color:#1c84ba;
        }
    }
    .nav{
        &>div{
            background-image: url(/img/sprite.png);
            background-repeat: no-repeat;
            width: 7px;
            height: 25px;
            cursor: pointer;
            position: absolute;
            left:0;
            &.prev{
                background-position: -50px 0;
                top:22px;
                &:hover{
                    background-position: -64px 0;
                }
            }
            &.next{
                background-position: -57px 0;
                bottom:7px;
                &:hover{
                    background-position: -71px 0;
                }
            }
        }
    }
    .slide-wrap{
        display: none;
        &.active{
            display: block;
        }
    }
}
.vacancy-slider{
    .vacancy-slide{
        margin-left:122px;
        margin-right: -20px;
        padding:22px;
        font-family: "LatoLight";
        position: relative;
        font-size: 15px;
        .title{
            font-family: "LatoRegular";
            font-size: 25px;
            margin-bottom: 15px;
            padding-right: 50px;
        }
        .row-info{
            margin-bottom: 3px;
            span{
                font-family: "LatoRegular";
            }
        }
        .btn{
            font-size: 15px;
            padding: 11px 20px 9px;
            margin-top:15px;
        }
        &:nth-child(2n+2){
            background:white;
        }
        .text-info{
            overflow: hidden;
            max-height: 56px;
        }
        &.active{
            .text-info{
                max-height: 9999px;
            }
        }
    }  
    
    .slide-button{
        width:28px;
        height:28px;
        background:#0a2339;
        border-radius: 2px;
        position: absolute;
        right:19px;
        top:19px;
        cursor: pointer;
        
        &:before{
            content:'';
            display: block;
            position: absolute;
            top: 13px;
            transition: all 0.3s;
            left: 9px;
            border-top:4px solid white;
            border-right:5px solid transparent;
            border-left:5px solid transparent;
        }
        &.active{
            &:before{
                transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
            }
        }
    }
}
.borders-box{
    border:10px solid #f1f1f1;
    position: relative;
}
.infrastructure-content{
    margin-bottom: 118px;
    margin-top:30px;
    .container-content{
        overflow: visible;
    }
    img{
        margin-top:-46px;
        margin-bottom: 40px;
        margin-right: 38px;
        float:left;
    }
    p{
        color: #2f2f2f;
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .info{
        display: table;
        li{
            list-style: none;
            display: inline-block;
            width:50%;
            font-size: 15px;
            font-family: "LatoLight";
            margin-bottom: 7px;
            span{
                border-bottom: 1px dotted #cecece;
                cursor: pointer;
            }
            &:before{
                content:'';
                display: block;
                float:left;
                width:7px;
                height:7px;
                border-radius: 50%;
                position: relative;
                top: 5px;
                margin-right: 8px;
            }
            &.inf-1:before{background:#df6900;}
            &.inf-2:before{background:#7a491e;}
            &.inf-3:before{background:#1172d4;}
            &.inf-4:before{background:#813fe5;}
            &.inf-5:before{background:#228929;}
            &.inf-6:before{background:#3aa0ff;}
            &.inf-7:before{background:#d22727;}
            &.inf-8:before{background:#bcbcbc;}
        }
    }
    .links{
        background: #f8f7f7;
        padding: 2px 2px;
        position: absolute;
        bottom: -16px;
        a{
            color:#2f2f2f;
            padding:0 10px;
            transition:all .3s;
            text-decoration: none;
            border-right:1px solid #dddcdc;
            white-space: nowrap;
            &:last-child{
                border-right:0px solid #dddcdc;
            }
            &:hover, &.active{
                color:#1c84ba;
                text-transform: none;
            }
        }
    }
}
.blog-content{
    margin:0 -1%;
    .blog-item{
        width:48%;
        margin:0 1%;
        color:#2f2f2f;
        float:left;
        margin-bottom: 38px;
        .blog-image{
            width: 100%;
            background-size: cover;
            background-position: center;
            padding-top: 29%;
            display: block;
        }
        .blog-name{
            color: #2f2f2f;
            text-decoration: none;
            font-size: 20px;
            transition: all .3s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-top: 14px;
            padding-bottom: 9px;
            display: block;
            &:hover{
                color:#1c84ba;
            }
        }
        .autor{
            .autor-icon{
                width:29px;
                height:29px;
                display: inline-block;
                background-size: cover;
                background-position: center;
                margin-right:7px;
                border-radius: 50%;
            }
            .autor-name{
                font-size: 11px;
                text-transform: uppercase;
                font-family: "LatoLight";
                position: relative;
                top:-9px;
                a{
                    text-decoration: none;
                    color:#1c84ba;
                    font-family: "LatoRegular";
                }
            }
        }
        .text{
            font-family: LatoLight;
            font-size: 15px;
            margin-top: 2px;
            margin-bottom: 14px;
            height: 120px;
            overflow: hidden;
            &>p{
                margin:0;
            }
        }
        .dop-info{
            overflow: hidden;
            font-family: "LatoLight";
            font-size: 10px;
            text-transform: uppercase;
            &>div{
                margin-right: 20px;
                float:left;
            }
            i{
                color: #2e8dbf;
                margin-right: 6px;
                font-size: 15px;
            }
        }
    }
}
.border-top{
    border-top:1px solid #eeeeee;
}
.pagination{
    text-align: center;
    padding-top: 37px;
    clear: both;
    padding-bottom: 104px;
    &>a{
        display: inline-block;
        width:29px;
        height:29px;
        border-radius: 2px;
        border:1px solid #eeeeee;
        background:white;
        text-decoration: none;
        color:#454545;
        font-size: 12px;
        text-align: center;
        line-height: 29px;
        transition: all 0.3s;
        &:hover{
            background:#e8e8e8;
        }
        &.disabled{
            background:white;
            cursor: default;
        }
        &.active{
            background: #1c84ba;
            border:1px solid #1c84ba;
            color:white;
            cursor: default;
        }
    }
}
.news-content{
    margin:0 -1%;
    .news-item{
        width:22%;
        margin:0 1.5%;
        display: block;
        float:left;
        color:#2f2f2f;
        transition: all 0.3s;
        text-decoration: none;
        margin-bottom: 45px;
        &:nth-child(4n+1){
            clear: both;
        }
        .text{
            font-family: LatoLight;
            font-size: 15px;
            margin-top: 2px;
        }
        .image{
            width:100%;
            padding-top: 60%;
            position: relative;
            background-size: cover;
            background-position: center;
            .inner-content-image{
                color:white;
                text-align: center;
                position: absolute;
                top:50%;
                width:100%;
                left:0;
                opacity: 0;
                transition: all 0.3s;
                margin-top:-7px;
                i{
                    margin-left: 5px;
                    position: relative;
                    top:1px;
                }
            }
            &:before{
                content: '';
                display: block;
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background: black;
                opacity: 0;
                transition: all 0.3s;
            }
        }
        .name{
            font-size: 20px;
            transition: all .3s;
            margin-bottom: 4px;
        }
        .dop-info{
            overflow: hidden;
            font-family: "LatoLight";
            font-size: 10px;
            margin:17px 0 13px 0;
            text-transform: uppercase;
            &>div{
                margin-right: 20px;
                float:left;
            }
            i{
                color: #2e8dbf;
                margin-right: 6px;
                font-size: 15px;
            }
        }
        &:hover{
            background:white;
            box-shadow: 0px 0px 0px 15px white;
            .image{
                .inner-content-image{
                    opacity: 1;
                }
                &:before{
                    opacity: 0.5;
                }
            }
            .name{
                color:##1c84ba;
            }
        }
    }
}
.articles-content{
    margin-top: -17px;
    margin-bottom: 55px;
    .article-item{
        display: block;
        color:#2f2f2f;
        clear: both;
        float:left;
        width:100%;
        transition: all 0.3s;
        margin:17px 0;
        text-decoration: none;
        .image{
            background-size: cover;
            background-position: center;
            width:370px;
            height:244px;
            float:left;
            position: relative;
            margin-right: 33px;
            .inner-content-image{
                color:white;
                text-align: center;
                position: absolute;
                top:50%;
                width:100%;
                left:0;
                opacity: 0;
                transition: all 0.3s;
                margin-top:-7px;
                i{
                    margin-left: 5px;
                    position: relative;
                    top:1px;
                }
            }
            &:before{
                content: '';
                display: block;
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:black;
                opacity: 0;
                transition: all 0.3s;
            }
        }
        .text{
            margin-left:403px;
            .name{
                font-size: 20px;
                transition: all .3s;
                margin-bottom: 6px;
                display: block;
                text-decoration: none;
                color:#2f2f2f;
                transition: all 0.3s;
            }
        }
        .dop-info{
            overflow: hidden;
            font-family: "LatoLight";
            font-size: 10px;
            margin:17px 0 13px 0;
            text-transform: uppercase;
            &>div{
                margin-right: 20px;
                float:left;
            }
            i{
                color: #2e8dbf;
                margin-right: 6px;
                font-size: 15px;
            }
        }
        &:hover{
            background: white;
            box-shadow: 0px 0px 0px 15px white, 0px 0px 0px 16px #eeeeee;
            .image{
                &:before{
                    opacity: 0.5;
                }
                .inner-content-image{
                    opacity: 1;
                }
            }
        }
    }
}
.stock-content{
    margin-top: -17px;
    margin-bottom: 55px;
    .stock-item{
        display: block;
        color:#2f2f2f;
        clear: both;
        float:left;
        width:100%;
        transition: all 0.3s;
        margin:20px 0;
        text-decoration: none;
        .btn{
            padding-left:30px;
            padding-right:30px;
            margin-top: 12px;
        }
        .image{
            background-size: cover;
            background-position: center;
            width:220px;
            height:220px;
            float:left;
            position: relative;
            margin-right: 21px;
        }
        .text{
            margin-left:241px;
            padding-right: 219px;
            position: relative;
            .date-stock{
                position: absolute;
                top:0;
                right:0;
                font-size: 15px;
                .date-span{
                    display: block;
                    font-size: 30px;
                    margin-top: 7px;
                }
            }
            .name{
                font-size: 20px;
                transition: all .3s;
                margin-bottom: 6px;
                display: block;
                text-decoration: none;
                color:#2f2f2f;
                transition: all 0.3s;
            }
        }
    }
}
.screen-auth{
    .first-screen__cont-wrap{
       border-left:none;
        text-align: center;
    }
}
.header__back{
    color:white !important;
    text-decoration: none !important;
}
.auth-block{
    h1{
        color:white;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 27px;
    }
    .form-block{
        text-align: left;
        background:white;
        padding:35px 45px;
        width:316px;
        margin:0 auto;
        margin-bottom: 50px;
        button{
            width: 100%;
            margin-top: 11px;
        }
    }
}
.stock-item-content{
    &__slider{
        margin-bottom: 55px; 
        position: relative;
        &-slide{
            &>div{
                overflow: hidden;
                color:#2f2f2f;
                position: relative;
                margin-top: 35px;
            }
            .stock-text{
                height:415px;
                padding:30px 37px;
                margin-right: 409px;
                position: relative;
                background:white;
                .title{
                    font-size: 18px;
                }
                &__inner{
                    position: absolute;
                    top: 30px;
                    left: 37px;
                    right: 22px;
                    bottom: 37px;
                    overflow: auto;
                    padding-right: 15px;
                    &::-webkit-scrollbar {
                        width:3px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #ededed;
                        border-radius: 50px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #0099cc;
                        border-radius: 50px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #00a8e0;
                    }
                    .btn{
                        padding: 10px 20px;
                    }
                }
            }
            .form{
                background:white;
                position: absolute;
                top:0;
                right:0;
                width: 315px;
                padding:30px 37px;
                textarea{
                    resize:none;
                }
                button{
                    width: 100%;
                    font-size: 15px;
                }
            }
        }
        .owl-nav{
            &>div{
                width:55px;
                height:103px;
                background:#1c84ba;
                transition: all 0.3s;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top:50%;
                opacity: 0.7;
                margin-top:-51px;
                &:hover{
                    opacity: 1;
                }
                &.owl-next{
                    right:-110px;
                    background-image: url(/img/arr-next.png);                    
                }
                &.owl-prev{
                    background-image: url(/img/arr-prev.png);  
                    left:-110px;
                }
            }
        }
    }
}
h1{
    font-size: 40px;
}
.container-no-overflow{
    .container-content{
        overflow: visible;
    }
}
.blog-item{
    position: relative;
    background-size: cover;
    background-position: center;
    .first-screen__cont-wrap{
        border-left:0;
        width: 100%;
    }
    p.subtitle{
        font-size: 20px;
        margin:0;
    }
    .hm-action-box{
        &.prev-item{
            margin-left: -110px;
            background: #1c84ba;
            height: 134px;
            position: absolute;
            margin-right: -55px;
            background-image: url(/img/arr-prev.png);
            background-repeat: no-repeat;
            background-position: center;
        }
        &__cont{
            width:230px;
            display: inline-block;
            font-size: 15px;
            line-height: 22px;
            color:#2f2f2f;
            font-family: "LatoRegular";
        }
        img{
            float:left;
            width:auto;
            height:134px;
        }
        &__right{
            background-image:url('/img/arr-next.png');
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
.stock-item-content__slider{
    .owl-nav{
        a{
            display: block;
            position: absolute;
            top:0;
            left:0;
            right: 0;
            bottom:0;
        }
    }
}
.content-blog-item{
    background:#f8f7f7;
    position: relative;
    padding-bottom: 106px;
    z-index: 2;
    .container-content{
        overflow: visible;
    }
    .item-content{
        background:white;
        border:1px solid #eeeeee;
        border-radius: 2px;
        margin-top: -78px;
        margin-bottom: 62px;
        &__header{
            border-bottom: 1px solid #eeeeee;
            .item-content-inner{
                padding-bottom: 6px;
            }
        }
        &__body{
            .item-content-inner{
                padding:20px 40px;
            }
            img{
                max-width: 100%;
            }
        }
        .user-icon{
            width:53px;
            height:53px;
            display: block;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            display: inline-block;
        }
        .user{
            float:left;
            span{
                text-transform: uppercase;
                position: relative;
                font-size: 13px;
                top: -20px;
                margin-left: 10px;
            }
        }
        .dop-info{
            float:right;
            overflow: hidden;
            font-family: "LatoLight";
            font-size: 10px;
            margin: 21px 0 0;
            text-transform: uppercase;
            &>div{
                margin-right: 20px;
                float:left;
            }
            i{
                color: #2e8dbf;
                margin-right: 6px;
                font-size: 15px;
            }
        }
        &-inner{
            padding:10px 40px;
            overflow: hidden;
        }
    }
    .comments{
        form{
            position: relative;
        }
        position: relative;
        .textarea{
            display: block;
            margin-right: 196px;
            textarea:focus{
                background:white;
            }
            
        }
        .btn{
            font-size: 15px;
            padding-left:36px;
            padding-right: 32px;
            position: absolute;
            right:0;
            top:0;
        }
        .comments-list{
            margin-top:10px;
            .comment-item{
                padding:28px 0 12px 64px;
                border-bottom:1px solid #eeeeee;
                &:last-child{
                    border-bottom: none;
                }
                &__inner{
                    position: relative;
                    min-height: 55px;
                }
                .user-icon{
                    position: absolute;
                    top:0;
                    left:-64px;
                    width:53px;
                    height:53px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    display: inline-block;
                }
                &-header{
                    span{
                        text-transform: uppercase;
                        font-size: 13px;
                    }
                    .dop-info{
                        display: inline-block;
                        overflow: hidden;
                        margin-left:15px;
                        font-family: "LatoLight";
                        font-size: 10px;
                        color:#969696;
                        text-transform: uppercase;
                        position: relative;
                        top: 6px;
                        span{
                            font-size: 10px;
                        }
                        i{
                            color: #2e8dbf;
                            margin-right: 6px;
                            font-size: 15px;
                        }
                    }
                    
                }
                p{
                    margin:0;
                    margin-top: 5px;
                    font-size: 15px;
                    font-family: "LatoLight";
                }
            }
        }
    }
}
.fancybox-type-image{
    .fancybox-close{
        background-color: white;
        z-index: 5;
        top: 20px;
        right: 20px;
    }
    .fancybox-nav{
        background-repeat: no-repeat;
        background-position: center;
        transition: all .3s;
        background-color: white;
        opacity: 0.4;
        width: 40px;
        height: 50px;
        top: 50%;
        margin-top: -25px;
        &:hover{
            opacity: 1;
        }
    }
    .fancybox-next{
        background-image:url(/img/slider-arrows-black-right.png);
        &:hover{
            background-image:url(/img/slider-arrows-right.png);
        }
    }
    .fancybox-prev{
        background-image:url(/img/slider-arrows-black-left.png);
        &:hover{
            background-image:url(/img/slider-arrows-left.png);
        }
    }
}
.fancybox-type-swf{
    .fancybox-inner{
        overflow: visible !important;
    }
    .fancybox-close{
        top:20px;
        right:20px;
        background-color:white;
        opacity: 1;
    }
}
.slider-control-block{
    width:284px;
    height:284px;
    padding: 0px 30px;
    box-sizing: border-box;
    color:white !important;
    text-align: left;
    float:left;
    position: relative;
    border:1px solid rgba(255,255,255,0.4);
    p{
        font-size: 15px;
        font-family: "LatoLight";
    }
    h1{
        line-height: 39px;
        margin-top: 17px;
    }
}
.nav-partner-slider{
    position: absolute;
    bottom: 20px;
    &>div{
        width:38px;
        height:38px;
        float:left;
        margin-right: 4px;
        transition: all 0.3s;
        cursor: pointer;
        border:1px solid rgba(255,255,255,0.4);
        position: relative;
        z-index: 2;
        &:before{
            content: '';
            background-image: url(/img/sprite.png);
            background-repeat: no-repeat;
            width: 25px;
            height: 7px;
            background-position: 0 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -12.5px;
            margin-top: -3.5px;
        }
        &:hover{
             border:1px solid rgba(255,255,255,1);
             box-shadow: 0px 0px 0px 1px white;
        }
        &.prev{
            
        }
        &.next{
            &:before{
                background-position: 0px -7px;
            }  
        }
    }
}
.partners{
    margin-top: 127px;
    margin-bottom: 140px;
    margin-left:54px;
    position: relative;
    .owl-dots{
        position: absolute;
        left:0;
        .owl-dots{
            float:none;
        }
    }
}
.partners-slider{
    margin-left:295px;
    display: none;
    position: static;
    .owl-nav{
        visibility: hidden;
    }
    .partner-item{
        width:auto;
        display: -ms-flexbox;
        display: flex;
        text-decoration: none;
        text-align: center;
        height:284px;
        border:1px solid rgba(255,255,255,0.4);
        position: relative;
        .hover-content{
            position: absolute;
            background:white;
            top:0;
            bottom:0;
            right:0;
            left:0;
            opacity: 0;
            transition: all 0.3s;
            color:#2f2f2f;
            &:hover{
                opacity: 1;
            }
            &:after{
                content:'';
                position: absolute;
                bottom:0;
                left:0;
                right:0;
                height:38px;
                background:white;
            }
            .title{
                text-transform: uppercase;
                font-size: 20px;
                text-align: center;
                color:#2f2f2f;
                margin-top: 29px;
                padding: 0 20px;
                width: auto;
            }
            p.text{
                font-family: "LatoLight";
                font-size: 15px;
                color:#2f2f2f;
                padding:0 20px;
                width: auto;
            }
        }
        .image{
            height:0;
            display: inline-block;
            position: relative;
            width: 94px;
            text-align: center;
            img{
                position: absolute;
                max-width:90%;
                left:0;
                max-height: 60px;
                top: -12px;
                transform: translate(0%, -50%);
                -moz-transform: translate(0%, -50%);
                -webkit-transform: translate(0%, -50%);
                -o-transform: translate(0%, -50%);
                -ms-transform: translate(0%, -50%);
            }
        }
        img{
            margin-right: 3px;
            max-width: 100px;
            display: inline-block;
        }
        &>p{
            text-align: center;
            width:100%;
            font-size: 28px;
            color:white;
            line-height: 29px;
            margin-top: 25px;
        }
    }
}
@media screen and (min-width:1215px){
    .right-inverse{
        &:before{
            content:'';
            display: block;
            position:fixed;
            top:0;
            right:0;
            bottom:0;
            width:50%;
            background:url(/img/bg-gm-10.jpg);
            background-size: cover;
            background-position: right;
        }
        .header__phone, .header__adress{
            color:white;
        }
        .breadcrumbs{
           border-top: 1px solid rgba(207, 207, 207, 0.26);
            border-bottom: 1px solid rgba(207, 207, 207, 0.26);
        }
            
        header .uk-float-right .btn{
            border: 1px solid #fff;
            border: 1px solid rgba(255,255,255,.39) !important;
            border-color: rgba(255,255,255,.39);
            color:white;
            &:after, &:before{
                border-color:white !important;
            }
        }
    }
}
.service-slider{
    margin-bottom: 20px;
    .service-slide{
        .text-slider{
            max-width: 45%;
            width:440px;
            padding-top:118px;
            float:left;
            margin-bottom: 40px;
            b{
                font-family: "LatoRegular";
            }
            font-family: "LatoLight";
            font-size: 15px;
            ul{
                list-style: none;
                li{
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        left: -10px;
                        top: 8px;
                        border-radius: 50%;
                        width: 4px;
                        height: 4px;
                        background: #4a9cc7;
                    }
                }
            }
        }
    }
    .buttons-service{
        background: #fff;
        margin-left: 480px;
        position: relative;
        margin-top:-118px;
        //top: -118px;
        background: white;
        margin-bottom: 36px;
        &>a{
            display: block; 
            float:left;
            width:50%;
            height:173px; 
            box-sizing: border-box;
            border:1px solid #f4f3f3;
            border-left:0px;
            border-top:0px;
            text-align: center;
            font-size: 15px;
            box-sizing: border-box;
            padding:0 10px;
            color:#2f2f2f;
            text-decoration: none;
            text-transform: uppercase;
            .icon{
                width:139px;
                height:82px;
                margin:0 auto;
                background-position: center bottom;
                background-repeat: no-repeat;
                margin-bottom: 12px;
                margin-top: 10px;
                transition: all 0.3s;
                &.ic-1{
                    background-image: url(/img/servise-icon-1.png);
                }
                &.ic-2{
                    background-image: url(/img/servise-icon-2.png);
                }
                &.ic-3{
                    background-image: url(/img/servise-icon-3.png);
                }
                &.ic-4{
                    background-image: url(/img/servise-icon-4.png);
                }
                &.ic-5{
                    background-image: url(/img/servise-icon-5.png);
                }
                &.ic-6{
                    background-image: url(/img/servise-icon-6.png);
                }
                &.ic-7{
                    background-image: url(/img/servise-icon-7.png);
                }
            }
            &:hover{
                .ic-1{
                    background-image: url(/img/servise-icon-1-h.png);
                }
                .ic-2{
                    background-image: url(/img/servise-icon-2-h.png);
                }
                .ic-3{
                    background-image: url(/img/servise-icon-3-h.png);
                }
                .ic-4{
                    background-image: url(/img/servise-icon-4-h.png);
                }
                .ic-5{
                    background-image: url(/img/servise-icon-5-h.png);
                }
                .ic-6{
                    background-image: url(/img/servise-icon-6-h.png);
                }
                .ic-7{
                    background-image: url(/img/servise-icon-7-h.png);
                }
                
            }
        }
    }
    
}
.right-inverse h1.ghost-text{
    margin-bottom: 46px;
} 
.contents-text{
    display: none;
    &.active{
        display: block;
    }
    .info-item-block{
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        display: none;
        background:white;
        padding:20px 70px;
        font-family: "LatoLight";
        overflow: auto;
        &.active{
            display: block;
        }
        &::-webkit-scrollbar {
            width:3px;
        }
        &::-webkit-scrollbar-track {
            background: #ededed;
            border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
            background: #0099cc;
            border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: #00a8e0;
        }
        .title{
            text-transform: uppercase;
            font-size: 18px;
            font-family: "LatoRegular";
        }
        ul{
            list-style: none;
            li{
                position: relative;
                font-size: 15px;
                &:before{
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: 8px;
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                    background: #4a9cc7;
                }
            }
        }
    }
    
}
.goBack-button{
    width:54px;
    height:103px;
    background:#1c84ba url('/img/arr-prev.png') center no-repeat;
    display: block;
    position: absolute;
    left:0;
    top:50%;
    margin-top: -51px;
    z-index: 3;
    cursor: pointer;
}